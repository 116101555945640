import React, { useState } from "react"

import { BlurredContainer } from "../styles/components/shared-styles"
import { formatPrice } from "../utils/currency-helpers"
import { getStripe } from "../utils/stripejs"

import { GatsbyImage } from "gatsby-plugin-image"

import { Product } from "../models/product"

import styled from "@emotion/styled"
import { Link } from "gatsby"

type PropsType = {
  product: Product
}

const ProductItemView = (props: PropsType) => {
  const [loading, setLoading] = useState(false)

  const { id, name, slug, unitAmount, currency, featuredImage } = props.product

  const productDetailUrl = `/products/${slug}`
  return (
    <Container>
      <Link key={slug} to={productDetailUrl}>
        <BlurredContainer>
          <GatsbyImage image={featuredImage} alt={name} />
        </BlurredContainer>

        <MetaAndBuyContainer>
          <Meta>
            <h5>{name}</h5>
            <h2>{formatPrice(unitAmount, currency)}</h2>
          </Meta>
          <BuyButton
            disabled={loading}
            onClick={event => handleSubmit(event, id, setLoading)}
          >
            <h4>Buy Now</h4>
          </BuyButton>
        </MetaAndBuyContainer>
      </Link>
    </Container>
  )
}

const handleSubmit = async (
  event: React.MouseEvent<Element, MouseEvent>,
  id: string,
  setLoading: React.Dispatch<React.SetStateAction<boolean>>
) => {
  event.preventDefault()
  setLoading(true)

  const stripe = await getStripe()

  if (stripe) {
    const { error } = await stripe.redirectToCheckout({
      mode: "payment",
      lineItems: [{ price: id, quantity: 1 }],
      successUrl: `${window.location.origin}/`,
      cancelUrl: `${window.location.origin}/failure/`,
    })

    if (error) {
      console.warn("Error:", error)
    }

    setLoading(false)
  }
}

export default ProductItemView

const Container = styled.div`
  display: flex;
  align-items: stretch;
  flex-direction: column;
  max-width: 350px;
  & a {
    text-decoration: none;
  }
`

const MetaAndBuyContainer = styled.div`
  display: flex;
  transition: transform 0.3s;
  justify-content: space-between;
  align-items: center;
  background-color: var(--bgOffset);
  border-radius: 10px;
  padding: 1rem;
  margin-top: 1rem;
  min-height: 6rem;
  color: var(--foreground);

  &:hover {
    transform: scale(1.05);
    cursor: pointer;
  }
`

const Meta = styled.div`
  flex-basis: 75%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`

const BuyButton = styled.button`
  flex-basis: 20%;
  border-radius: 5px;
  border: none;
  background-color: var(--foreground);
  transition: transform 0.3s;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;

  &:hover {
    transform: scale(1.15);
    cursor: pointer;
  }
`
